<template>
  <div ref="previewWrap" class="preview-wrap">
    <figure class="preview" :style="previewImageStyle">
      <div v-if="!previewData || loading"></div>
      <div v-else class="preview_btn_container">
        <button
          class="previewbtn btn pnew"
          type="button"
          @click.prevent="saveAndShowModal"
        >
          <AppIcon componentName="SearchIcon" /> Preview
        </button>
      </div>
      <img v-if="previewData" :src="previewData" alt="" class="absolute-fit" />
      <transition name="fade">
        <div v-if="!previewData || loading" class="preview-loader">
          <AppButtonLoader :size="100" color="#4874e6" />
        </div>
      </transition>
    </figure>
    <PreviewModal
      v-show="isModalVisible"
      @close="closeModal"
      :images="preview"
    />
  </div>
</template>

<script>
import PreviewModal from "@/components/creation/PreviewModal";
import AppButtonLoader from "@/components/ui/AppEmbedLoader";
import { throttle } from "lodash/function";
import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";

const ASPECT_RATIO = 1.3;

export default {
  name: "PreviewChanges",
  components: { AppButtonLoader, PreviewModal },

  props: {
    preview: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    previewData() {
      let previewLink = "";

      for (let i = 0; i < this.preview.length; i++) {
        previewLink = this.preview[i];
        break;
      }
      return previewLink;
    },

    onResizePreviewBlockThrottle() {
      return throttle(function () {
        this.updatePreviewAspect();
      }, 100);
    },

    previewImageStyle() {
      let styles = {};

      if (window.innerWidth > 991) {
        styles = {
          height: `${this.previewAspectRatio.height}px`,
          width: `${this.previewAspectRatio.width}px`,
        };
      }

      return styles;
    },
  },

  data() {
    return {
      resizeObserver: null,
      isModalVisible: false,
      previewAspectRatio: {
        height: 192,
        width: 136,
      },
      switch: true,
    };
  },

  methods: {
    updatePreviewAspect() {
      const containerWidth = this.$refs.previewWrap.clientWidth;
      const containerHeight = this.$refs.previewWrap.clientHeight;

      const previewTemporaryHeight = containerWidth * ASPECT_RATIO;

      if (previewTemporaryHeight > containerHeight) {
        this.previewAspectRatio.height = containerHeight;
        this.previewAspectRatio.width = containerHeight / ASPECT_RATIO;

        return;
      }

      this.previewAspectRatio.height = previewTemporaryHeight;
      this.previewAspectRatio.width = containerWidth;
    },

    saveAndShowModal() {
      this.switch = true;
      this.submitForm();
    },
    isFilled(activeForm) {
      let selectedLevel;
      if (activeForm.classList.contains("skill-form")) {
        selectedLevel = activeForm.querySelector(".level--selected");
      }
      const inputFields = activeForm.querySelectorAll("input");
      const isFilled = Array.from(inputFields).some((input) => {
        return input.value.length > 0;
      });
      return isFilled || selectedLevel;
    },
    submitForm() {
      this.$nextTick(() => {
        const activeForm = document.querySelector(
          ".experience-form.experience-form--active"
        );
        if (activeForm) {
          if (this.switch) {
            if (this.isFilled(activeForm)) {
              if (
                this.$route.name == CREATING_RESUME_NAMES.STEP_2 ||
                this.$route.name == CREATING_RESUME_NAMES.STEP_3 ||
                this.$route.name == CREATING_RESUME_NAMES.STEP_4
              ) {
                activeForm
                  .querySelector(".experience-form-btns__item--submit")
                  .click();
              }
              this.switch = false;
            } else {
              this.switch = 0;
              activeForm
                .querySelector(".experience-form-btns__item--cancel")
                .click();
            }
            this.submitForm();
          } else if (this.switch === 0) {
            this.showModal();
          }
        } else {
          this.showModal();
        }
      });
    },

    showModal() {
      this.previewData = this.preview;
      this.$emit("openPrevModal");
      this.isModalVisible = true;
    },
    closeModal() {
      this.$emit("closePrevModal");
      this.isModalVisible = false;
    },
  },

  mounted() {
    if (window.innerWidth > 991) {
      this.updatePreviewAspect();

      this.resizeObserver = new ResizeObserver(() => {
        this.onResizePreviewBlockThrottle();
      });

      this.resizeObserver.observe(this.$refs.previewWrap);
    }
  },

  beforeDestroy() {
    if (window.innerWidth > 991 && !!this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.previewWrap);
    }
  },
};
</script>

<style lang="scss" scoped>
.preview-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  position: relative;
}

.preview {
  border-radius: 8px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 130%;
  border: 1px solid #ddd8d8;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-top: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
  }
}

::v-deep .preview-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.6);
  backdrop-filter: blur(5px);
}
button.previewbtn svg {
  width: 12px;
  padding: 0;
  vertical-align: middle;
  margin: 0;
}
button.previewbtn {
  z-index: 1;
  position: inherit;
  border: 1px solid #303030;
  border-radius: 30px;
  width: 75px;
  font-size: 11px;
  bottom: 7px;
  right: 7px;
  background: #fff;
  padding: 0;
  margin: 0;
  height: 22px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: bold;
  color: #585858;
  box-shadow: 0 0 10px -3px #a5a5a5;
}
.preview_btn_container {
  position: absolute;
  width: 100%;
  z-index: 9;
  bottom: 2px;
}
.modal-backdrop {
  z-index: 9;
}
</style>
