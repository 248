<template>
  <div class="modal-backdrop">
    <div class="previewmodal">
      <div class="close_container">
        <button type="button" class="btn-black" @click="close">x</button>
      </div>

      <div class="prevcontainer" v-for="i in [currentIndex]" :key="i">
        <img :src="currentImg" />
      </div>
      <div class="thumbscontainer">
        <ul>
          <li v-for="imageLink in images" :key="imageLink">
            <figure>
              <img :src="imageLink" alt="" />
            </figure>
          </li>
        </ul>
      </div>

      <a class="prev" @click="prev" href="#" v-if="current_slide_number != 0"
        >&#10094;</a
      >
      <a
        class="next"
        @click="next"
        href="#"
        v-if="images.length - 1 != current_slide_number"
        >&#10095;</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CREATING_RESUME_NAMES,
  CREATING_COVER_LETTER_NAMES,
} from "@/helpers/rout-constants";

export default {
  name: "PreviewModal",

  props: {
    images: {
      type: String,
    },
  },

  data() {
    return {
      timer: null,
      currentIndex: 0,
      current_slide_number: 0,
    };
  },
  created() {},
  mounted: function () {
    // this.startSlide();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    startSlide: function () {
      this.timer = setInterval(this.next, 10);
    },
    next: function () {
      this.currentIndex += 1;
      this.current_slide_number++;
    },
    prev: function () {
      this.currentIndex -= 1;
      this.current_slide_number--;
    },
  },

  computed: {
    ...mapGetters({
      previewResumeAll: "resumeCreation/getResumePreviewAll",
      previewLetterAll: "coverLetterCreation/getCoverLetterPreviewAll",
    }),
    isResume() {
      return Object.values(CREATING_RESUME_NAMES).includes(this.$route.name);
    },

    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>

<style lang="scss" scoped>
.disabledButton {
  background: none !important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-black {
  color: #fff;
  background: #4874e6;
  border-radius: 50px;
  width: 27px;
  height: 27px;
  padding: 0px;
  font-size: 14px;
  font-weight: bold;
  margin-right: -10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

img {
  height: 600px;
  width: 100%;
  padding: 10px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 50px;
  background: #2d6ccb;
  padding: 0 10px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  border: 3px solid #fff;
  right: -40px;
  @media (max-width: 500px) {
    right: 10px;
  }
}

.prev {
  left: 0;
  border-radius: 50px;
  background: #2d6ccb;
  padding: 0 10px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  border: 3px solid #fff;
  left: -40px;
  @media (max-width: 500px) {
    left: 10px;
  }
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.close_container {
  position: relative;
  width: 100%;
  text-align: right;
  margin-bottom: -20px;
  @media (max-width: 500px) {
    width: 95%;
  }
}
.previewmodal {
  position: inherit;
  width: 28%;
  @media (max-width: 500px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 501px) {
    width: 90%;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    width: 645px;
  }
}
img {
  height: auto !important;
}
.thumbscontainer {
  display: block;
  width: 100%;
  text-align: center;
}
.thumbscontainer ul li {
  display: inline-block;
  background: #fff;
  text-align: center;
  max-width: 70px;
  margin: 0 10px;
  border-radius: 5px;
}
</style>
